<template>
  <b-container>
    <b-card bg-variant="light" class="text-center mb-5">
      <b-card-title class="text-center mb-4">{{ $t('vacation.vacationOverview') }}</b-card-title>
      <!-- use this with a 'key' to reload the vacation Info when the absence list changes -->
      <VacationOverview :manage-all-users="true" :key="allAbsencesChangedTimestamp" class="mt-3" />
    </b-card>
    <b-card bg-variant="light" class="mb-3">
      <b-card-title class="text-center mb-5" :title="$t('vacation.vacationTable')" />
      <AbsenceTable
        :all-absences="allAbsences"
        :user-list="userList"
        :reload-function="reloadData"
        :manage-all-users="true"
      />
    </b-card>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import 'vue2-datepicker/index.css'
import AbsenceTable from '@/components/AbsenceTable'
import { LocalDate } from '@/util/LocalDateTimeFormatter'
import VacationOverview from '@/components/VacationOverview'

export default {
  name: 'ManageAbsences',
  mixins: [ApiMixin, NotificationMixin],
  components: { VacationOverview, AbsenceTable },
  data() {
    return {
      allAbsences: null,
      allAbsencesChangedTimestamp: null,
      userList: []
    }
  },
  created() {
    this.getAllActiveUsers()
    this.loadAbsences()
  },
  methods: {
    getAllActiveUsers() {
      if (this.isAdmin) {
        let self = this
        this.getRequest(
          '/users',
          new RequestConfig().onSuccess(res => {
            self.userList = res.data
          })
        )
      } else {
        this.userList = [this.$store.getters.getCurrentUser]
      }
    },
    loadAbsences() {
      let self = this
      return this.getRequest(
        '/absences/all',
        new RequestConfig().onSuccess(res => {
          self.allAbsences = res.data
          self.allAbsencesChangedTimestamp = LocalDate.getISOTimestamp()
        })
      )
    },
    reloadData() {
      this.loadAbsences()
    }
  }
}
</script>
